<template>
  <div>
    <b-card :title="`Job ${jobDisplayId} Details`">
      <b-row>
        <b-col>
          <dl>
            <dt>Job</dt>
            <dd>{{ job.displayId }}</dd>

            <dt>Protection Policy</dt>
            <dd>
              <b-link :to="{ name: 'ppdm-detail.protection-policy-detail', params: { name: job.protectionPolicyName }}">
                {{ job.protectionPolicyName }}
              </b-link>
            </dd>

            <dt>State</dt>
            <dd>{{ job.state }}</dd>

            <dt>Category</dt>
            <dd>
              {{ job.categoryName }}
              <small
                v-if="job.subCategoryName"
                class="text-muted"
              >{{ job.subCategoryName }}
              </small>
            </dd>

            <template v-if="job.storageSystemName">
              <dt>Storage System</dt>
              <dd>{{ job.storageSystemName }}</dd>

              <template v-if="job.storageStystemType">
                <dt>Storage System Type</dt>
                <dd>{{ job.storageStystemType }}</dd>
              </template>
            </template>
          </dl>
        </b-col>

        <b-col>
          <dl>
            <dt>Started on</dt>
            <dd>{{ job.start | formatDateTimeTz(asup.timezone) }}</dd>

            <template v-if="job.end">
              <dt>Ended on</dt>
              <dd>{{ job.end | formatDateTimeTz(asup.timezone) }}</dd>

              <dt>Duration</dt>
              <dd>{{ job.duration | formatClrTimeSpan }}</dd>
            </template>

            <dt>Progress</dt>
            <dd>{{ job.progress }}%</dd>
          </dl>
        </b-col>

        <b-col>
          <dl>
            <dt>Assets</dt>
            <dd>{{ job.numberOfAssets }}</dd>

            <template v-if="job.assetSizeInBytes">
              <dt>Asset Size</dt>
              <dd>{{ job.assetSizeInBytes | prettyBytes }}</dd>
            </template>

            <template v-if="job.bytesTransferredThroughput">
              <dt>Transferred Throughput</dt>
              <dd>{{ job.bytesTransferredThroughput | prettyBytes }} / {{ job.bytesTransferredThroughputUnitOfTime }}</dd>
            </template>

            <template v-if="job.bytesTransferred">
              <dt>Bytes Transferred</dt>
              <dd>{{ job.bytesTransferred | prettyBytes }}</dd>
            </template>

            <template v-if="job.preCompBytes">
              <dt>Pre Comp.</dt>
              <dd>{{ job.preCompBytes | prettyBytes }}</dd>
            </template>

            <template v-if="job.postCompBytes">
              <dt>Post Comp.</dt>
              <dd>{{ job.postCompBytes | prettyBytes }}</dd>
            </template>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      :title="`Asset Jobs (${orderedJobs.length})`"
    >
      <JobTable
        :jobs="orderedJobs"
        :time-range="{ startDate: $moment(job.start).toISOString(), endDate: $moment(job.start).add(1, 'day').toISOString()}"
        :timezone="asup.timezone"
        type="protection-policy"
      />
    </b-card>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'

import PpdmService from '@/service/ppdm.service'
import JobTable from './components/JobTable.vue'

export default {
  components: {
    BRow, BCol, BCard, BLink, JobTable,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      job: {},
    }
  },
  computed: {
    jobDisplayId() {
      return this.$route.params.displayId
    },
    orderedJobs() {
      if (!this.job || !this.job.jobs) {
        return []
      }
      return this.job.jobs.concat().sort((a, b) => (a.start > b.start ? -1 : 1))
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      PpdmService.getJobAsync(this.asup.id, this.jobDisplayId, { disableTenantFilter: true })
        .then(result => {
          this.job = result
        })
    },
  },
}

</script>
